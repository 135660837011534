@import "../../_globalColor";

.experience-card {
  position: relative;
  background-color: $lightBackground2;
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
}

.experience-card-dark {
  position: relative;
  background-color: $darkBackground;
  box-shadow: $lightBoxShadowDark 0px 10px 30px -15px;
  border-radius: 10px;
  border: 1px solid $lightBorder1;
}

.experience-card:hover {
  box-shadow: $lightBoxShadowDark 0px 20px 30px -10px;
}

.experience-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150%;
  background: $experienceCardBannerLG;
  border-radius: 10px 10px 0px 0px;
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
  filter: blur(0px);
  height: 11rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.experience-blurred_div {
  position: absolute;
  background: $experienceCardBlurredDivLG;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  height: 11rem;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}

.experience-div-company {
  position: absolute;
  background: transparent;
  height: 9rem;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.experience-text-company {
  text-align: center;
  padding: 1.5rem;
  margin: 0;
  color: $lightBackground1;
  font-size: 25px;
  text-align: center;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.experience-roundedimg {
  position: absolute;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 7rem;
  margin-left: auto;
  margin-right: auto;
  width: 8rem;
  height: 8rem;
  box-shadow: 0 0.5rem 1rem $lightBoxShadowDarker;
  border-radius: 50%;
}

.experience-text-role {
  text-align: center;
  color: $textColor;
  font-weight: 700;
  font-size: 25px;
  margin: 0px;
  padding-top: 1.5rem;
  line-height: normal;
}

.experience-text-date {
  text-align: center;
  color: $textColor;
  font-size: 20px;
  margin: 0px;
  padding-top: 1rem;
  font-weight: 600;
}

.experience-text-desc {
  .dark-mode-text {
    color: $textColorDark !important;
  }
  text-align: center;
}

.experience-text-details {
  padding: 1.5rem;
  margin-top: 2rem;
}

.experience-text-participated-projects{
  text-align: left;
  padding-left: 2.5rem;
  padding-top: 1.5rem;
  color: $textColor;
  font-weight: 700;
  font-size: 25px;
  margin: 0px;
  // padding-top: 1.5rem;
  line-height: normal;
}
.company-description {
  text-align: center;
  font-weight: 700;
}
.team-size {
  text-align: left;
  font-weight: 700;
  font-size: 20px;
}
//tech-span dark-mode-text


// ===================================
.experience-projects {
  .experience-proj {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden; 

    //Text tech-stack is the same to description, children are classes tech-span placed in a box with a border and shadow, fixed with text and place next to each other
    .tech-stack {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      padding: 0 15px;
      margin: 0;
      margin-bottom: 20px;
      list-style-type: none;
      font-size: 20px;
      line-height: 1.5;
      line-height: 1.5;
    }
    
    .video-container {
      position: relative;
      width: 40%; // Chiều rộng tối đa của container
      &:before {
        content: "";
        display: block;
        padding-top: (634 / 357) * 80%; // Tính toán tỉ lệ padding dựa trên kích thước video
      }
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .description {
      .dark-mode-text {
        color: $textColorDark ;
      }
      .tech-span {
        .dark-mode-text {
          color: black !important;
        }
        color: $textColor;
        margin: 0px 5px;
        padding: 5px 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        background-color: $lightBackground1;
        font-size: 15px;
        //line spacing between each tech-span
        line-height: 1.5;
      }
      flex: 1;
      padding: (5px 20px);
      margin: 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    
    
    .fab
    {
      padding-left: 20px;
    }

    h5 {
      font-size: 1.875rem;
      margin-bottom: 10px;
      line-height: 1.2;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin-bottom: 10px;

      li {
        margin-top: 10px;
        margin-bottom: 10px;
        color: $textColor;
        line-height: 1.5;
        //Canh đều
        // text-align: justify !important;
      }
    }

    a {
      color: #007bff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 768px) {
    .experience-proj {
      flex-direction: column;

      .video-container {
        &:before {
          padding-top: (634 / 357) * 100%; // Duy trì tỉ lệ cho mobile
        }
      }
    }
  }
}

// ===================================

/* Media Query */

@media (max-width: 1380px) {
  .experience-text-role {
    padding-top: 0.5rem;
    font-size: 22px;
  }
  .experience-text-company {
    font-size: 22px;
  }
  .experience-text-date {
    font-size: 18px;
  }
  .experience-roundedimg {
    width: 6.5rem;
    height: 6.5rem;
  }
}

@media (max-width: 768px) {
  .experience-text-role {
    font-size: 22px;
  }
  .experience-text-company {
    font-size: 22px;
  }
  .experience-text-date {
    font-size: 18px;
  }
  .experience-text-desc {
    font-size: 16px;
  }
}
